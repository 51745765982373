export function scrollDisplayInit(){
  var pageTop = $(window).scrollTop();

  $('[data-js-scroll-display-elm]').each(function(i){
    var parentH = $(window).outerHeight();   
    var offsetTop = $(this).offset().top;

    if((offsetTop - parentH) < 0){
      let delay = 1000;  // 0.1秒ずつずれます。好きな秒数に調整してください。
      $(this).delay(i * delay).queue(function(next){
          $(this).addClass('is-show');
          next();
      });          
    }
  })  
}

export function scrollDisplay(){
  var pageTop = $(window).scrollTop();
  // console.log(pageTop)
  var parentH = $(window).outerHeight();
  
  $('[data-js-scroll-display-elm]').each(function(i){      
    var offsetTop = $(this).offset().top;
    var elmH = $(this).height();

    if((pageTop + parentH) >= Math.round(offsetTop)){
      let delay = 800;
      $(this).delay(delay).queue(function(next){
          $(this).addClass('is-show');
          next();
      });          
    }
  })  
}

export function fvDisplay(){
  var windowH = $(window).outerHeight();
  var parent = $('[data-js-fv-display-parent]');
  parent.height(windowH);
}