import '../scss/style.scss';
import 'slick-carousel';
import { fvDisplay, } from './scrollDisplay.js';
import './deSVG.js';
import {gsapAnimation01} from './gsap.js';
import BackgroundCheck from './background-check-master/background-check.js'
import 'jquery.mb.ytplayer';
import lottie from 'lottie-web';

var device_now = null;
var time = new Date().getTime();
var windowScroll;
var flag = {
  'data-js-slick02' : false,
  'data-js-top-loading': false,
  'load': false,
  'fCheckDomain': false,
}

$(function(){
  deSVG('.c_btn02 img, .c_btn04 img',true);

  if(location.pathname=='/'){
    fvDisplay();
    $('[data-js-YT-player01]').YTPlayer();
    topProjectSlide();
  }

  if((location.pathname=='/project/')||(!location.pathname.indexOf('/project/page/'))){
    fDiplayProjectImage();
  }

  if(location.pathname=='/about/'){
    setMovieByYTApi();
    fOnToggleModal01();
  }

  //前のページとドメインが同じか確認
  flag['fCheckDomain']=fCheckDomain();

  if(flag['fCheckDomain']){
    $('[data-js-top-loading]').hide();
    topFvSlide();  
  }else{
    if(location.pathname!=='/'){
      setTimeout(function(){stopload()},10000);
    }else{
      $('body').addClass('is_no-scroll');
      fSvgAnimation01();
    }
  }
});

$(window).on("load", function(){
  flag['load'] = true;
  if(flag['fCheckDomain']){
    BackgroundCheck.init({
      targets: '.c_nav01',
      threshold: 80,
    });
    setTimeout(function(){
      BackgroundCheck.refresh();
    },100)    
    controlSpMenu();
    $('body').removeClass('is_no-scroll');      
  }else{
    if(location.pathname!=='/'){
      BackgroundCheck.init({
        targets: '.c_nav01',
        threshold: 80,
      });
      setTimeout(function(){
        BackgroundCheck.refresh();
      },100)          
      controlSpMenu();
      $('body').removeClass('is_no-scroll');
    }else{
      if(flag['load']&&flag['data-js-top-loading']){
        stopload();
      } 
    }    
  }

  if(!location.pathname.indexOf('/project/')){
    fCheckGutenbergColumsImage();
  }

  fBrowseBackReload();
  headerDisplay();
  btn01Transition();
  fSvgAnimation02();

 
  //お問合わせページ
  checkFormAgreement();
  formReset();
  addError();  
});

$(window).scroll(function(){
  windowScroll = $(window).scrollTop();
  // slidePlayTiming('[data-js-slick02]');

  // var elm = document.getElementById('nav');
  // console.log(elm.getBoundingClientRect())
})

$(window).resize(function(){
  if(location.pathname=='/'){
    fvDisplay();
  }
  controlSpMenu();
})

function fCheckDomain(){
  var url = new URL(window.location.href);
  var prev_url = document.referrer;
  console.log(url.host)
  console.log(prev_url)
  if(prev_url.match('/'+url.host+'/')){
    return true;
  }else{
    return false;
  }
}

function stopload(){
  BackgroundCheck.init({
    targets: '.c_nav01',
    threshold: 80,
  });
  setTimeout(function(){
    BackgroundCheck.refresh();
  },100)   
  $('[data-js-top-loading]').fadeOut(800);
  topFvSlide();
  controlSpMenu();
  setTimeout(function(){
    $('body').removeClass('is_no-scroll');
  },1000)  
}

function topFvSlide(){
  $('[data-js-slick01],[data-js-slick01-delay]').not('.slick-initialized').slick({
    infinite: true,
    fade: true,
    speed: 2000,
    autoplaySpeed: 2000,
    autoplay: true,
    arrows: false,
    swipe: false,
    pauseOnHover: false,
    pauseOnFocus: false,
  });
  $('[data-js-slick01-delay]').slick('slickPause');
  BackgroundCheck.init({
    targets: '.c_nav01',
    threshold: 80,
  });  
  setTimeout(function(){
    $('[data-js-slick01-delay]').slick('slickPlay');
  },1000);
  $('[data-js-slick01-delay]').on('afterChange', function(slick) {
    BackgroundCheck.refresh();
  });
}

function topProjectSlide(){
  $('[data-js-slick02]').not('.slick-initialized').slick({
    infinite: true,
    fade: true,
    speed: 2000,
    autoplaySpeed: 2000,
    autoplay: true,
    arrows: false,
    dots: false,
  });
  var elm = $('.c_slide02__indicator .c_slide02__wrap01').eq(0);
  elm.addClass('is_active');
  gsapAnimation01(elm.children());
  $('[data-js-slick02]').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    console.log(nextSlide)
    $('.c_slide02__indicator .c_slide02__wrap01').removeClass('is_active');
    var elm = $('.c_slide02__indicator .c_slide02__wrap01').eq(nextSlide);
    elm.addClass('is_active');
    gsapAnimation01(elm.children());
  });
}

function slidePlayTiming(elm){
  var key = elm.slice(1).slice(0,-1);
  console.log(flag[key])
  if(!flag[key]){
    var pos = $(elm).offset();
    console.log(pos)
    var windowH = $(window).outerHeight();
    console.log(windowScroll)
    if( pos.top < (windowScroll + windowH + 400)){
      $(elm).slick('slickPlay');
      flag[key] = true;
    }
  }
}

function checkDevice(){
  var windowW = $(window).outerWidth();
  if(windowW>1023){
    return 'pc';
  }else{
    return 'sp';
  }
}

function noticeChangeDevice(){
  var device = checkDevice();
  if(device_now!==device){
    device_now = device;
    return device_now;
  }
}

function btn01Transition(){
  $('.c_btn01,.c_btn04,[data-js-hover-transiton01]').addClass('is-out');
  $('.c_btn01,.c_btn04,[data-js-hover-transiton01]').mouseover(function(){
    $(this).removeClass('is-out');
    $(this).addClass('is-hover');
  })
  $('.c_btn01,.c_btn04,[data-js-hover-transiton01]').mouseout(function(){
    $(this).removeClass('is-hover');
    $(this).addClass('is-out');
  })  
}

function controlSpMenu(){
  var flag = noticeChangeDevice();
  if(flag=='pc'){
    controlSpMenuForPc();
  }else if(flag=='sp'){
    controlSpMenuForSp();
  }
}

function controlSpMenuForPc(){
  $('[data-js-toggle-menu-btn]').removeClass('is-close')
  $('[data-js-toggle-menu]').fadeIn();
  $('body').removeClass('is_no-scroll');
  $('[data-js-toggle-menu-btn]').unbind();
}

function controlSpMenuForSp(){
  $('[data-js-toggle-menu]').hide();
  $('[data-js-toggle-menu-btn]').click(function(){
    if($(this).hasClass('is-close')){
      $(this).removeClass('is-close')
      $('[data-js-toggle-menu]').fadeOut(function(){
        $('[data-js-header-display]').css('height','auto');
      });
      $('body').removeClass('is_no-scroll');
      // $('[data-js-hide-logo]').animate({ opacity: 0 }, { duration: 400, easing: 'linear'})
      if($(window).scrollTop()<50){
        $('[data-js-hide-logo]').removeClass('is-show');
      }      
    }else{
      $(this).addClass('is-close')
      $('[data-js-toggle-menu]').fadeIn();
      $('body').addClass('is_no-scroll');
      // $('[data-js-hide-logo]').animate({ opacity: 1 }, { duration: 400, easing: 'linear'})
      $('[data-js-hide-logo]').addClass('is-show');
      $('[data-js-header-display]').css('height','100%');
    }
  })   
}

// form 同意チェック
function checkFormAgreement(){
  var flag = $('[data-js-check-form-agreement] input[type="checkbox"]').prop("checked");
  var func = function(){
    toggleDisabled('[data-js-check-form-agreement-btn] input[type="submit"]',flag);
  }
  func();
  $('[data-js-check-form-agreement] input[type="checkbox"]').click(function(){
    flag = $('[data-js-check-form-agreement] input[type="checkbox"]').prop("checked"); 
    console.log("クリック")
    func();
  })
}

function toggleDisabled(elm,flag){
  if(flag){
    $(elm).prop('disabled', false); 
  }else{
    $(elm).prop('disabled', true); 
  }
}

function formReset(){
  $('[data-js-form-reset]').bind('click', function(){
    $('#mw_wp_form_mw-wp-form-81 form').find('textarea, input[type="text"]').val('').end().find('input:checked').prop('input:checked', false).end().find('select').val('選択');;
  });
}

function headerDisplay(){
  var scroll = 0;
  $(window).on('scroll', function(){
    if($(this).scrollTop() < scroll ){
      $('.l_header').removeClass('is_hide')
      $('.l_header').css('position','fixed')
    }
    else{
      $('.l_header').addClass('is_hide')
      $('.l_header').css('position','fixed')
    }
    scroll = $(this).scrollTop();
    if(scroll > 50){
      $('[data-js-hide-logo]').addClass('is-show');
    }else{
      $('[data-js-hide-logo]').removeClass('is-show');
    }    
    if(scroll<1){
      $('.l_header').removeClass('is_hide')
    }
  });
}

//エラーがあった時に「入力内容にエラーがあります。下記のメッセージをご確認ください。」を表示させる。
function addError(){
  if($('.error').length){
    $('[data-js-add-error]').show();
  }else{
    $('[data-js-add-error]').hide();
  }
}

function fOnToggleModal01(){
  $('[data-js-modal01]').hide();
  $('[data-js-modal01-btn]').on('click',function(){
    $('body').addClass('is_no-scroll');
    $('[data-js-modal01]').fadeIn();
  })
  $('[data-js-modal01-btn-close]').on('click',function(){
    $('[data-js-modal01]').fadeOut();
    $('body').removeClass('is_no-scroll');
  })  
}

function setMovieByYTApi(){
  const ytiframe= 'player';
  //iframeで表示しているwindowオブジェクトを取得
  const targetWindow = document.getElementById(ytiframe).contentWindow;

  //APIのコマンドを送信する関数
  const ag2ytControl = function(action,arg=null){
    targetWindow.postMessage('{"event":"command", "func":"'+action+'", "args":'+arg+'}', '*');
  };

  $('[data-js-modal01-btn]').on('click',function(){
    ag2ytControl('playVideo');
  })

  $('[data-js-modal01-btn-close]').on('click',function(){
    ag2ytControl('stopVideo');
  })  
}

function fDiplayProjectImage(){
  $('[data-js-diplay-image-img]').hide();
  $('[data-js-diplay-image-list]').each(function(){
    var num = $(this).attr('data-js-diplay-image-list');
    var link = $(this).attr('href');
    if(checkDevice()=='pc'){
      $(this).mouseenter(function(){
        $('[data-js-diplay-image-img="'+num+'"]').fadeIn(300,function(){
          BackgroundCheck.refresh();
        });
        $('[data-js-diplay-image-list-parent]').addClass('is-white');
      })
      $(this).mouseleave(function(){
        $('[data-js-diplay-image-img]').fadeOut(300,function(){
          BackgroundCheck.refresh();
        });
        $('[data-js-diplay-image-list-parent]').removeClass('is-white');
      })
    }else{
      $(this).on('click',function(e){
        e.preventDefault();
        $('[data-js-diplay-image-list-parent]').addClass('is-white');
        $('[data-js-diplay-image-img="'+num+'"]').show();
        setTimeout(function(){window.location.href = link;},150);
      })
      $(this).on('mouseover',function(e){
        // console.log(e)
        e.preventDefault();
        $('[data-js-diplay-image-list-parent]').addClass('is-white');
        $('[data-js-diplay-image-img="'+num+'"]').show();
      })
      $(this).on('touchstart',function(e){
         // console.log(e)
        $('[data-js-diplay-image-img]').hide();
        $('[data-js-diplay-image-list-parent]').removeClass('is-white');
        $('.c_btn01,.c_btn04,[data-js-hover-transiton01]').removeClass('is-hover');
        $('.c_btn01,.c_btn04,[data-js-hover-transiton01]').addClass('is-out');
      })
    }
  })    
}

function fBrowseBackReload(){
  // https://on-ze.com/archives/7522
  window.addEventListener('pageshow',()=>{
    if(window.performance.navigation.type==2){
      $('[data-js-diplay-image-img]').hide();
      $('[data-js-diplay-image-list-parent]').removeClass('is-white');  
      $('.c_btn01,.c_btn04,[data-js-hover-transiton01]').removeClass('is-hover');
      $('.c_btn01,.c_btn04,[data-js-hover-transiton01]').addClass('is-out');             
    }
  });  
}

function fSvgAnimation01(){
  //http://airbnb.io/lottie/#/web
  var animation = lottie.loadAnimation({
     container: document.getElementById('loading'),　//HTMLのID
     renderer: 'svg',
     loop: false, //ループ
     autoplay: true,　//自動再生
     path: 'wp-content/themes/classglass/images/socg-logo.json',//jsonファイルの場所
 });

  animation.addEventListener('complete', e=> {
    flag['data-js-top-loading'] = true;
    if(flag['load']&&flag['data-js-top-loading']){
      stopload();
    }
  });
}

function fSvgAnimation02(){
  //http://airbnb.io/lottie/#/web
  var animation = lottie.loadAnimation({
     container: document.getElementById('l_fv02'),　//HTMLのID
     renderer: 'svg',
     loop: false, //ループ
     autoplay: true,　//自動再生
     path: '/wp-content/themes/classglass/images/socg-shape.json',//jsonファイルの場所
 });
}

function fCheckGutenbergColumsImage(){
  $('.wp-block-image').children('img').wrap('<div class="wrap">');
  $('.wp-block-columns').each(function(){
    var array = [];
    $(this).children().each(function(){
      var width = $(this).children('.wp-block-image').find('img').width();
      var height = $(this).children('.wp-block-image').find('img').height();
      var aspect = height / width;
      // aspect = Math.round(aspect * 10) / 10;
      array.push(aspect)
    })
    var max = Math.max(...array);
    var min = Math.min(...array);
    var dif = max - min;
    var flag = array.every(value => value === array[0])
    // console.log(array);
    // console.log(dif);
    if(dif>0.15){
      $(this).addClass('is_def_aspect');
    }else{
      $(this).addClass('is_same_aspect');
      $(this).children().each(function(){
        $(this).children('.wp-block-image').find('.wrap').css('padding-top', min*100+'%');
        $(this).children('.wp-block-image').find('.wrap').children('img').css({
          position : 'absolute',
          top : 0,
        })
      })
      console.log(min);
    }
  })
}