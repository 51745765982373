import { gsap } from 'gsap';

export function gsapAnimation01(elm){
  var tween =
    gsap.fromTo(
      elm,
      {
        x: 0,
      },
      {
        duration: 4,
        ease: 'none',
        x: 30,
      })
  tween.restart();
}